@import "../../../../../theme/index.module.scss";

.Container {
  position: relative;
  background: white;
  display: flex;
  padding: 1.5em;
}

.Image {
  width: 80%;
  height: 90px;
  background-size: cover !important;
  background-position: center top !important;
}

.Content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}

.Title {
  position: relative;

  h1 {
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    margin: 0;
    color: black;
  }

  &:after {
    content: "";
    width: 70px;
    height: 1px;
    background: red;
    position: absolute;
    margin: .75em 0;
  }
}

.Category {
  margin-top: 1.2em;
  position: relative;

  div {
    font-size: 12px;
    letter-spacing: 0.2em;
    font-weight: 800;
    color: black;
  }

  p {
    font-size: 12px;
    letter-spacing: 0.2em;
    text-transform: capitalize;
    color: black;
  }
}

.Paragraph {
  display: none;
  width: 100%;
  margin: 2em 0 1em;

  p {
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    color: black;

    a {
      color: #E50100;
    }
  }

  mark {
    background: #e6020182;
    padding: 2px 5px;
  }
}
